import React, { ReactNode } from "react";
import { OverlayTrigger } from "react-bootstrap";
import InfiniteCarousel from "react-leaf-carousel";
import styled from "styled-components";

import placeholder from "../images/placeholder.png";
import MetadataPopover from "./MetadataPopover";

const CarouselImage = styled.img`
height: 250px !important;
width: unset !important;
`;

type ResultCarouselProperties = {
    customerConfig?: CustomerConfig,
    items: Array<Record<string, any>>,
    onItemClick: (event: string) => void
}

export class ResultCarousel extends React.Component<ResultCarouselProperties> {

    private isEmptyOrSpaces = (value: string) => {
        return value === undefined || value === null || value.match(/^ *$/) !== null;
    }

    private getErrorImage = (event: any): void => {
        event.target.src = placeholder;
    }

    private getImageUrl = (result: any): string => {
        const imageUrl = result?.["imageUrl"];
        const imageDotUrl = result?.["image"]?.["url"];

        try {
            if (!this.isEmptyOrSpaces(imageUrl)) {
                return imageUrl;
            }

            if (!this.isEmptyOrSpaces(imageDotUrl)) {
                return imageDotUrl;
            }
        } catch (error) {
            //Whatever.
        }

        return placeholder;
    }

    private getTrailerUrl = (result: any): string => {
        const trailerContentUrl = result?.["trailer"]?.["contentUrl"];

        try {
            if (!this.isEmptyOrSpaces(trailerContentUrl)) {
                return trailerContentUrl;
            }
        } catch (error) {
            //Whatever.
        }

        return placeholder;
    }

    public render(): ReactNode {
        return (
            <InfiniteCarousel
                dots={true}
                showSides={true}
                sidesOpacity={.4}
                sideSize={.2}
                slidesToScroll={6}
                slidesToShow={6}
                scrollOnDevice={true}
                style={{"height":"200px"}}
            >
                {this.props.items.length > 0 &&
                    (this.props.items.map((item, i) => {
                        return (
                            <OverlayTrigger
                                key={i}
                                placement={"auto"}
                                overlay={<MetadataPopover item={item} minimal />}
                            >
                                <CarouselImage
                                    src={this.getImageUrl(item)}
                                    alt={item["id"]}
                                    onError={this.getErrorImage}
                                    key={i}
                                    onClick={this.props.onItemClick.bind(this, this.getTrailerUrl(item))}
                                />
                            </OverlayTrigger>
                        );}))}
            </InfiniteCarousel>
        );
    }
}

export default ResultCarousel;